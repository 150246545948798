import React, { useEffect, useState } from 'react';
import { initializeParticipant, getGameChip } from './solana';
import WalletButton from './components/WalletButton';
import GameBoard from './components/GameBoard';
import './App.css';

function App() {
  const [chipCount, setChipCount] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [inGame, setInGame] = useState(false);
  const [processingTx, setProcessingTx] = useState(false);
  const [chipAmount, setChipAmount] = useState(1);

  const isPhantomInstalled = () => {
    return window.solana && window.solana.isPhantom;
  };

  const connectWallet = async () => {
    if (isPhantomInstalled()) {
      try {
        const resp = await window.solana.connect();
        console.log('Connected with public key:', resp.publicKey.toString());
        setWalletAddress(resp.publicKey.toString());
        setWalletConnected(true);
        await initializeParticipant();
        await updateChipCount(resp.publicKey.toString());
      } catch (err) {
        console.error('Error connecting to Phantom Wallet:', err);
      }
    } else {
      alert('Phantom Wallet not found. Please install it.');
    }
  };

  const handleBuyChip = async () => {
    setProcessingTx(true);
    setShowModal(false);
    try {
      const serverStatus = await fetch('https://moniomok.xyz:3001/status');
      if (!serverStatus.ok) {
        throw new Error('Server is not running');
      }

      const txHash = await getGameChip(chipAmount);
      console.log(`Chip purchase transaction hash: ${txHash}`);
      await updateChipCount(walletAddress);
    } catch (err) {
      if (err.message.includes("Transaction was not confirmed in 30.00 seconds")) {
        console.log("Timeout error:", err.message);
      } else if (err.message.includes("insufficient funds")) {
        alert("Need more $MONI in your wallet!");
      } else {
        console.error('Error buying chip:', err);
        alert('Error buying chip: ' + err.message);
      }
    } finally {
      setProcessingTx(false);
    }
  };

  const updateChipCount = async (walletAddress) => {
    try {
      const response = await fetch(`https://moniomok.xyz:3001/chipCount?walletAddress=${walletAddress}`);
      const data = await response.json();
      setChipCount(data.chipCount);
    } catch (err) {
      console.error('Error fetching chip count:', err);
    }
  };

  useEffect(() => {
    if (walletConnected) {
      updateChipCount(walletAddress);
    }
  }, [walletConnected, walletAddress]);

  return (
    <div className="App" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/background.png'})` }}>
      <header className="App-header">
        <h1>Monimok</h1>
        {walletConnected ? (
          <>
            <p className="wallet-address">Connected wallet address: {walletAddress}</p>
            <p>Chips: {chipCount !== null ? chipCount : 'Loading...'}</p>
            {!inGame && <button onClick={() => setShowModal(true)} disabled={processingTx}>Buy Chips</button>}
            {processingTx && <p>Processing transaction now.. Don't refresh!!</p>}
            <GameBoard walletAddress={walletAddress} setInGame={setInGame} />
          </>
        ) : (
          <WalletButton connectWallet={connectWallet} />
        )}
      </header>
      {showModal && (
        <div className="overlay">
          <div className="modal">
            <div className="modal-content">
              <h2>Confirm Chip Purchase</h2>
              <p>Do you really want to purchase chips?</p>
              <p>1 chip = 10 $MONI</p>
              <label>
                Select amount: 
                <select value={chipAmount} onChange={(e) => setChipAmount(Number(e.target.value))}>
                  {Array.from({ length: 10 }, (_, i) => i + 1).map(amount => (
                    <option key={amount} value={amount}>{amount}</option>
                  ))}
                </select>
              </label>
              <button onClick={handleBuyChip}>Confirm</button>
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

import * as anchor from '@project-serum/anchor';
import { Connection, PublicKey, SystemProgram } from '@solana/web3.js';
import idl from './idl.json';
import { Buffer } from 'buffer';
import {
  getOrCreateAssociatedTokenAccount,
  TOKEN_PROGRAM_ID,
  getAccount
} from "@solana/spl-token";

const programId = new PublicKey("GWS62PHUozZsjJWDZi7tKmwmRkazVVCkFzv6kPZ87uHq");

const getProvider = () => {
  if (!window.solana) {
    throw new Error("Solana 객체를 찾을 수 없습니다! Phantom Wallet을 설치하세요 👻");
  }

  const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=e65ed3a3-10c4-4fd1-9863-ec34586a2176', 'processed');
  const wallet = window.solana;
  return new anchor.AnchorProvider(connection, wallet, anchor.AnchorProvider.defaultOptions());
};

const getProgram = () => {
  const provider = getProvider();
  return new anchor.Program(idl, programId, provider);
};

export const initializeParticipant = async () => {
  const program = getProgram();
  const player = program.provider.wallet.publicKey;
  const [participantPDA] = await PublicKey.findProgramAddress(
    [Buffer.from("participant"), player.toBuffer()],
    program.programId
  );

  try {
    await program.account.participant.fetch(participantPDA);
    console.log("참가자 계정이 이미 존재합니다");
  } catch (e) {
    console.log("참가자 계정 초기화 중...");
    const txHash = await program.methods.initializeParticipant()
      .accounts({
        player: player,
        participant: participantPDA,
        systemProgram: SystemProgram.programId,
      })
      .rpc();

    console.log(`참가자 초기화 완료, 서명: ${txHash}`);
    await program.provider.connection.confirmTransaction(txHash);
  }
};

export const getGameChip = async (amount) => {
  const program = getProgram();
  const player = program.provider.wallet.publicKey;
  const gameOwner = new PublicKey("J4g4Qg8vRMKHymSyhaKU5o2KcJsyMeWCEZYNuguMxmki");
  const mint = new PublicKey("BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL");

  // 게임 토큰 계정 가져오기 또는 생성
  let gameTokenAccount;
  try {
    const tokenAccounts = await program.provider.connection.getParsedTokenAccountsByOwner(
      gameOwner,
      { mint }
    );
    console.log("게임 소유자 토큰 계정:", tokenAccounts.value);
    if (tokenAccounts.value.length === 0) {
      gameTokenAccount = await getOrCreateAssociatedTokenAccount(
        program.provider.connection,
        program.provider.wallet.payer,
        mint,
        gameOwner
      );
      console.log("게임 토큰 계정 생성:", gameTokenAccount.address.toBase58());
    } else {
      gameTokenAccount = tokenAccounts.value[0].pubkey;
      console.log("기존 게임 토큰 계정 사용:", gameTokenAccount.toBase58());
    }
  } catch (err) {
    console.error("게임 토큰 계정을 찾거나 생성하는 중 오류 발생:", err);
    throw new Error("게임 토큰 계정을 찾거나 생성할 수 없습니다.");
  }

  // 플레이어 토큰 계정 가져오기 또는 생성
  let playerTokenAccount;
  try {
    playerTokenAccount = await getOrCreateAssociatedTokenAccount(
      program.provider.connection,
      program.provider.wallet.payer,
      mint,
      player
    );
    console.log("플레이어 토큰 계정 생성 또는 찾기 성공:", playerTokenAccount.address ? playerTokenAccount.address.toBase58() : playerTokenAccount.toBase58());
  } catch (err) {
    console.error("플레이어 토큰 계정을 가져오거나 생성하는 중 오류 발생:", err);
    throw new Error("플레이어 토큰 계정을 가져오거나 생성할 수 없습니다.");
  }

  // 참가자 PDA 정의
  const [participantPDA] = await PublicKey.findProgramAddress(
    [Buffer.from("participant"), player.toBuffer()],
    program.programId
  );

  // 칩 구매 트랜잭션
  const amountBN = new anchor.BN(10000000 * amount);  // 10 SPL Token (assuming 6 decimals) * amount
  try {
    const txHash = await program.methods.getGameChip(amountBN)
      .accounts({
        player: player,
        playerTokenAccount: playerTokenAccount.address ? playerTokenAccount.address : playerTokenAccount,
        gameTokenAccount: gameTokenAccount,
        participant: participantPDA,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      })
      .rpc();

    console.log(`트랜잭션 확인, 서명: ${txHash}`);
    await program.provider.connection.confirmTransaction(txHash);

    const response = await fetch('https://moniomok.xyz:3001/buyChip', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ walletAddress: player.toString(), txHash, amount })
    });

    if (!response.ok) {
      throw new Error('Chip purchase failed');
    }

    return txHash;
  } catch (err) {
    if (err.message.includes("Transaction was not confirmed in 30.00 seconds")) {
      console.log("트랜잭션 시간 초과 오류:", err.message);
      const matchedTxHash = err.message.match(/Check signature (\w+) using/);
      if (matchedTxHash) {
        const timeoutTxHash = matchedTxHash[1];
        console.log(`타임아웃 오류에서 트랜잭션 서명: ${timeoutTxHash}`);

        const response = await fetch('https://moniomok.xyz:3001/buyChip', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ walletAddress: player.toString(), txHash: timeoutTxHash, amount })
        });

        if (!response.ok) {
          throw new Error('Chip purchase failed');
        }

        return timeoutTxHash;
      } else {
        throw new Error("Could not extract transaction signature from timeout error.");
      }
    } else {
      if (err.message.includes("insufficient funds")) {
        alert("Need more $MONI in your wallet!");
      }
      console.error("칩 구매 트랜잭션 중 오류 발생:", err);
      throw new Error(err.message);
    }
  }
};
